define("spotassist/helpers/wind-image-name", ["exports", "spotassist/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Helper.extend({
    wind_0: _environment.default.rootURL + "assets/images/wind_arrow_0.png",
    wind_30: _environment.default.rootURL + "assets/images/wind_arrow_30.png",
    wind_60: _environment.default.rootURL + "assets/images/wind_arrow_60.png",
    wind_90: _environment.default.rootURL + "assets/images/wind_arrow_90.png",
    wind_120: _environment.default.rootURL + "assets/images/wind_arrow_120.png",
    wind_150: _environment.default.rootURL + "assets/images/wind_arrow_150.png",
    wind_180: _environment.default.rootURL + "assets/images/wind_arrow_180.png",
    wind_210: _environment.default.rootURL + "assets/images/wind_arrow_210.png",
    wind_240: _environment.default.rootURL + "assets/images/wind_arrow_240.png",
    wind_270: _environment.default.rootURL + "assets/images/wind_arrow_270.png",
    wind_300: _environment.default.rootURL + "assets/images/wind_arrow_300.png",
    wind_330: _environment.default.rootURL + "assets/images/wind_arrow_330.png",
    //compute(params, hash) {
    compute: function compute(params) {
      var direction_from = params[0];

      if (direction_from >= 345 || direction_from < 15) {
        return this.get('wind_0');
      }

      if (direction_from >= 15 && direction_from < 45) {
        return this.get('wind_30');
      }

      if (direction_from >= 45 && direction_from < 75) {
        return this.get('wind_60');
      }

      if (direction_from >= 75 && direction_from < 105) {
        return this.get('wind_90');
      }

      if (direction_from >= 105 && direction_from < 135) {
        return this.get('wind_120');
      }

      if (direction_from >= 135 && direction_from < 165) {
        return this.get('wind_150');
      }

      if (direction_from >= 165 && direction_from < 195) {
        return this.get('wind_180');
      }

      if (direction_from >= 195 && direction_from < 225) {
        return this.get('wind_210');
      }

      if (direction_from >= 225 && direction_from < 255) {
        return this.get('wind_240');
      }

      if (direction_from >= 255 && direction_from < 285) {
        return this.get('wind_270');
      }

      if (direction_from >= 285 && direction_from < 315) {
        return this.get('wind_300');
      }

      if (direction_from >= 315 && direction_from < 345) {
        return this.get('wind_330');
      }
    }
  });

  _exports.default = _default;
});