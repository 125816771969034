define("spotassist/templates/components/shape-editor", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "/PNtcISO",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[7,\"span\"],[11,\"class\",\"input-group-btn\"],[9],[0,\"\\n  \"],[7,\"button\"],[12,\"class\",[28,[\"btn \",[27,\"if\",[[23,[\"addMode\"]],\"btn-success\",\"btn-default\"],null]]]],[12,\"disabled\",[21,\"deleteMode\"]],[11,\"type\",\"button\"],[9],[0,\"Add\"],[3,\"action\",[[22,0,[]],\"startAddMode\",[23,[\"item\"]]]],[10],[0,\"\\n\\n  \"],[7,\"button\"],[12,\"class\",[28,[\"btn \",[27,\"if\",[[23,[\"deleteMode\"]],\"btn-success\",\"btn-default\"],null]]]],[12,\"disabled\",[21,\"addMode\"]],[11,\"type\",\"button\"],[9],[0,\"Delete\"],[3,\"action\",[[22,0,[]],\"startDeleteMode\",[23,[\"item\"]]]],[10],[0,\"\\n\\n  \"],[7,\"button\"],[11,\"class\",\"btn btn-default text-right\"],[11,\"type\",\"button\"],[9],[0,\"Cancel\"],[3,\"action\",[[22,0,[]],\"cancelModes\",[23,[\"item\"]]]],[10],[0,\"\\n\\n\"],[10],[0,\"\\n\\n\"],[4,\"if\",[[23,[\"addMode\"]]],null,{\"statements\":[[0,\"  \"],[7,\"span\"],[9],[0,\"Draw on the map to add out landing area\"],[10],[0,\"\\n\"]],\"parameters\":[]},null],[4,\"if\",[[23,[\"deleteMode\"]]],null,{\"statements\":[[0,\"  \"],[7,\"span\"],[9],[0,\"Click on the out landing area to delete it\"],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[14,1]],\"hasEval\":false}",
    "meta": {
      "moduleName": "spotassist/templates/components/shape-editor.hbs"
    }
  });

  _exports.default = _default;
});