define("spotassist/helpers/format-public-url", ["exports", "spotassist/utils/generate-public-url"], function (_exports, _generatePublicUrl) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Helper.extend({
    compute: function compute(params) {
      //compute(params, hash) {
      var dropzoneId = params[0];
      var alias = params[1];
      return (0, _generatePublicUrl.default)(dropzoneId, alias);
    }
  });

  _exports.default = _default;
});