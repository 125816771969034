define("spotassist/templates/dialogs/new-user", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "MkU/1DvD",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"dialog-body\"],[9],[0,\"\\n\"],[4,\"if\",[[23,[\"contextObject\",\"error\"]]],null,{\"statements\":[[0,\"        \"],[7,\"div\"],[11,\"class\",\"error\"],[9],[1,[23,[\"contextObject\",\"error\"]],false],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n    \"],[7,\"form\"],[11,\"class\",\"w-form\"],[12,\"onsubmit\",[27,\"action\",[[22,0,[]],\"accept\"],null]],[9],[0,\"\\n        \"],[7,\"div\"],[11,\"class\",\"form-group\"],[9],[0,\"\\n            \"],[7,\"label\"],[11,\"for\",\"email\"],[9],[0,\"Email address:\"],[10],[0,\"\\n            \"],[1,[27,\"input\",null,[[\"placeholder\",\"type\",\"class\",\"value\"],[\"User Email\",\"email\",\"form-control\",[23,[\"contextObject\",\"email\"]]]]],false],[0,\"\\n        \"],[10],[0,\"\\n\\n        \"],[7,\"div\"],[11,\"class\",\"form-group\"],[9],[0,\"\\n            \"],[7,\"label\"],[11,\"for\",\"pwd\"],[9],[0,\"Expires:\"],[10],[0,\"\\n            \"],[1,[27,\"bootstrap-datepicker\",null,[[\"autoclose\",\"placeholder\",\"todayBtn\",\"format\",\"class\",\"value\"],[true,\"Permission expiration\",\"linked\",\"MM d yyyy\",\"form-control\",[23,[\"contextObject\",\"expires\"]]]]],false],[0,\"\\n\"],[0,\"        \"],[10],[0,\"\\n\\n    \"],[10],[0,\"\\n\"],[10],[0,\"\\n\\n\"],[7,\"div\"],[11,\"class\",\"dialog-footer\"],[9],[0,\"\\n    \"],[7,\"button\"],[11,\"class\",\"btn btn__accept\"],[12,\"disabled\",[23,[\"contextObject\",\"saveDisabled\"]]],[11,\"type\",\"submit\"],[9],[0,\"Save\"],[3,\"action\",[[22,0,[]],\"accept\"]],[10],[0,\"\\n    \"],[7,\"button\"],[11,\"class\",\"btn btn__decline\"],[11,\"type\",\"button\"],[9],[0,\"Cancel\"],[3,\"action\",[[22,0,[]],\"decline\"]],[10],[0,\"\\n\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "spotassist/templates/dialogs/new-user.hbs"
    }
  });

  _exports.default = _default;
});