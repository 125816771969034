define("spotassist/locations/hashbang", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HashLocation.extend({
    /*
    getURL: function() {
      var url = Ember.get(this, 'location').hash.substr(2);
      console.info("getURL: "+url);
       return url;
    },
     setURL: function(path) {
      var setUrl = '!'+path;
      console.info("setURL location: "+setUrl);
      Ember.get(this, 'location').hash = setUrl;
      console.info("setURL lastSetURL: "+setUrl);
      Ember.set(this, 'lastSetURL', setUrl);
    },
     onUpdateURL: function(callback) {
      var self = this;
      var guid = Ember.guidFor(this);
       Ember.$(window).bind('hashchange.ember-location-'+guid, function() {
        Ember.run(function() {
          var path = location.hash.substr(2);
          console.info("onUpdateURL path: "+path);
          if (Ember.get(self, 'lastSetURL') === path) {
            return;
          }
          Ember.set(self, 'lastSetURL', null);
          console.info("onUpdateURL callback: "+path);
          callback(path);
        });
      });
    },
     formatURL: function(url) {
      var formattedUrl = '#!'+url
      console.info("formatURL: "+formattedUrl);
      return formattedUrl;
    }
    */
    getHash: function getHash() {
      return this._super().replace('#!', '#');
    },
    setURL: function setURL(path) {
      Ember.get(this, 'location').hash = '!' + path;
      Ember.set(this, 'lastSetURL', path);
    },
    replaceURL: function replaceURL(path) {
      Ember.get(this, 'location').replace("#!".concat(path));
      Ember.set(this, 'lastSetURL', path);
    }
  });

  _exports.default = _default;
});