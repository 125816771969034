define("spotassist/templates/components/notify-updater-button", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "rSTdXCK5",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[7,\"a\"],[11,\"href\",\"#\"],[12,\"class\",[28,[\"btn btn-xs1 \",[27,\"if\",[[23,[\"error\"]],\"alert alert-danger\"],null]]]],[9],[0,\"\\n\"],[4,\"if\",[[23,[\"progress\"]]],null,{\"statements\":[[0,\"        \"],[7,\"i\"],[11,\"class\",\"fa fa-spinner fa-spin \"],[9],[10],[0,\"\\n\"]],\"parameters\":[]},null],[4,\"if\",[[23,[\"success\"]]],null,{\"statements\":[[0,\"        \"],[7,\"i\"],[11,\"class\",\"fa fa-check \"],[9],[10],[0,\"\\n\"]],\"parameters\":[]},null],[4,\"if\",[[23,[\"error\"]]],null,{\"statements\":[[0,\"        \"],[7,\"i\"],[11,\"class\",\"fa fa-exclamation-triangle \"],[9],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"    Notify Updater\\n\"],[3,\"action\",[[22,0,[]],\"notifyUpdaterAction\"]],[10],[0,\"\\n\\n\"],[14,1],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "spotassist/templates/components/notify-updater-button.hbs"
    }
  });

  _exports.default = _default;
});