define("spotassist/controllers/profile", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    session: Ember.inject.service('session'),
    queryParams: ['dz_id'],
    dz_id: null,
    dzIdObserver: Ember.observer('dz_id', function () {
      //console.log('will load dz id='+this.get('dz_id'));
      var that = this;
      this.get('store').findRecord('dropzone', this.get('dz_id')).then(function (dropzone) {
        that.set('selectedDropzone', dropzone);
      });
    })
  });

  _exports.default = _default;
});