define("spotassist/components/dropzone-weather", ["exports", "spotassist/config/environment", "moment", "spotassist/utils/correct-weather", "spotassist/utils/conversion-utils", "spotassist/objects/metar"], function (_exports, _environment, _moment, _correctWeather, _conversionUtils, _metar) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    rootURL: _environment.default.rootURL,
    dialog: Ember.inject.service(),
    session: Ember.inject.service(),
    router: Ember.inject.service('-routing'),
    actions: {
      loginClicked: function loginClicked(presenter) {
        presenter.accept();
        this.get('router').transitionTo('auth.login');
      },
      saveClicked: function saveClicked(presenter) {
        this.set('weatherSource.metarStation.metarObject.corrected_temp_c', this.get('weatherSource.metarStation.metarObject.temp_c'));
        var speed_userValue = this.get('edit_speed');
        var speedSystemValue = speed_userValue;

        if (this.get('localSettings').get('settings.speed_unit')) {
          speedSystemValue = _conversionUtils.default.convertSpeed(speed_userValue, this.get('localSettings').get('settings.speed_unit'), 'kts');
        }

        this.set('weatherSource.metarStation.metarObject.corrected_wind_speed_kt', parseInt(speedSystemValue.split(" ")[0]));
        var wind_dir_degrees_intValue = parseInt(this.get('edit_wind_dir_degrees'));
        this.set('weatherSource.metarStation.metarObject.corrected_wind_dir_degrees', wind_dir_degrees_intValue); //this.adjustCorrectionTime();

        var that = this;
        var metar = this.get('weatherSource.metarStation.metarObject');
        that.set('saveProgress', true);
        that.set('saveError', false);
        (0, _correctWeather.default)(metar, function (returnedMetar) {
          that.set('saveError', false);
          that.set('saveProgress', false);
          that.set('weatherSource.metarStation.metarObject', _metar.default.create(returnedMetar));
          presenter.accept();
        }, function () {
          that.set('saveProgress', false);
          that.set('saveError', true);
        });
      },
      setWeatherCorrectionMode: function setWeatherCorrectionMode() {
        if (!this.get("session.isAuthenticated")) {
          this.get("dialog").show("dialogs/need-login-layout", "dialogs/need-login", this, {
            acceptHandler: "loginClicked" //declineHandler: "noClicked",
            //keydown: e => { console.log(e.keyCode); }

          });
        } else if (!this.get("session.userAcl").hasDropzoneWeatherCorrectionAccess(this.get("dropzone.id"))) {
          this.get("dialog").alert("dialogs/need-login", this);
        } else {
          //this.get("dialog").confirm("dialogs/weather-corrections", this);
          this.setEditValues();
          this.get("dialog").show("dialogs/save-cancel-layout", "dialogs/weather-corrections", this, {
            acceptHandler: "saveClicked" //declineHandler: "noClicked",
            //keydown: e => { console.log(e.keyCode); }

          });
        }
      }
    },
    setEditValues: function setEditValues() {
      var temp_c_intValue = parseInt(this.get('weatherSource.metarStation.metarObject.temp_cValue'));

      var temp_userValue = _conversionUtils.default.convertTemperature(temp_c_intValue, 'C', this.get('localSettings').get('settings.temperature_unit'));

      this.set('edit_temp', temp_userValue.split(' ')[0]);
      var wind_speed_kt_intValue = parseInt(this.get('weatherSource.metarStation.metarObject.wind_speed_ktValue'));

      var speed_userValue = _conversionUtils.default.convertSpeed(wind_speed_kt_intValue, 'kts', this.get('localSettings').get('settings.speed_unit'));

      this.set('edit_speed', speed_userValue.split(' ')[0]);
      this.set('edit_wind_dir_degrees', this.get('weatherSource.metarStation.metarObject.wind_dir_degreesValue'));
    },
    adjustCorrectionTime: function adjustCorrectionTime() {
      //var localMoment = moment();
      var utcMoment = (0, _moment.default)().utc();
      var correctionTime = utcMoment.format('YYYY-MM-DDThh:mm:ss') + 'Z'; //"2017-06-02T04:54:00Z"

      this.set('metar.correction_time', correctionTime);
    },
    weatherLoadingError: Ember.computed('weatherLoadingSuccess', function () {
      var weatherLoadingSuccess = this.get('weatherLoadingSuccess');

      if (weatherLoadingSuccess != null && !weatherLoadingSuccess) {
        return true;
      } else {
        return false;
      }
    })
    /*
    metar_temp_c: computed('metar', {
      get(){
        return this.get('metar.temp_c');
      },
      set(key, value) {
        var intValue = parseInt(value);
        this.set('metar.temp_c', intValue);
         this.adjustCorrectionTime();
        return value;
      }
    }),
    metar_wind_speed_kt: computed('metar', {
      get(){
        return this.get('metar.wind_speed_kt');
      },
      set(key, value) {
        var intValue = parseInt(value);
        this.set('metar.wind_speed_kt', intValue);
         this.adjustCorrectionTime();
        return value;
      }
    }),
    metar_wind_dir_degrees: computed('metar', {
      get(){
        return this.get('metar.wind_dir_degrees');
      },
      set(key, value) {
        var intValue = parseInt(value);
         this.set('metar.wind_dir_degrees', intValue);
         this.adjustCorrectionTime();
        return intValue;
      }
    })
    */

  });

  _exports.default = _default;
});