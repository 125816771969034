define("spotassist/helpers/format-altitude", ["exports", "spotassist/utils/conversion-utils"], function (_exports, _conversionUtils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Helper.extend({
    compute: function compute(params) {
      //compute(params, hash) {
      var value = params[0];
      var sourceUnit = params[1];
      var destUnit = params[2];
      return _conversionUtils.default.convertAltitude(value, sourceUnit, destUnit);
    }
  });

  _exports.default = _default;
});