define("spotassist/models/bigpicture-vote", ["exports", "ember-data/model", "ember-data/attr"], function (_exports, _model, _attr) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  //import Ember from 'ember';
  var _default = _model.default.extend({
    dropzone: (0, _attr.default)(),
    user: (0, _attr.default)(),
    vote_location: (0, _attr.default)(),
    timestamp: (0, _attr.default)()
  });

  _exports.default = _default;
});