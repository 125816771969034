define("spotassist/objects/gfs-windaloft-level-object", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  //import ENV from "spotassist/config/environment";

  /* global com */
  var GfsWindaloftLevel = Ember.Object.extend({
    wind_direction_degree: null,
    wind_speed_ms: null,
    temp_c: null,
    height_m: null,
    init: function init() {
      this._super();

      this.set('__interfaces', "com.livewings.spotassist.library.gfs.IGfsWindaloftLevel");
    },
    getWind_direction_degree: function getWind_direction_degree() {
      return this.get('wind_direction_degree');
    },
    getWind_speed_ms: function getWind_speed_ms() {
      return this.get('wind_speed_ms');
    },
    getTemp_c: function getTemp_c() {
      return this.get('temp_c');
    },
    getHeight_m: function getHeight_m() {
      return this.get('height_m');
    }
  });
  var _default = GfsWindaloftLevel;
  _exports.default = _default;
});