define("spotassist/templates/components/highlight-js", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "lE0J3zWS",
    "block": "{\"symbols\":[\"number\"],\"statements\":[[4,\"if\",[[23,[\"hasLineNumbers\"]]],null,{\"statements\":[[0,\"  \"],[7,\"ul\"],[11,\"class\",\"ember-highlight-line-numbers\"],[9],[0,\"\\n\"],[4,\"each\",[[23,[\"lineNumbers\"]]],null,{\"statements\":[[0,\"      \"],[7,\"li\"],[9],[1,[27,\"unbound\",[[22,1,[]]],null],false],[10],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"  \"],[10],[0,\"\\n\"]],\"parameters\":[]},null],[7,\"pre\"],[9],[0,\"  \"],[1,[21,\"highlight\"],true],[0,\"\"],[10],[0,\"\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "spotassist/templates/components/highlight-js.hbs"
    }
  });

  _exports.default = _default;
});