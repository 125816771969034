define("spotassist/mixins/trackable-route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    metrics: Ember.inject.service(),
    trackPage: function trackPage(options) {
      var _this = this;

      Ember.run.scheduleOnce('afterRender', this, function () {
        if (options) {
          Ember.get(_this, 'metrics').trackPage(options);
        } else {
          //const page = this.get('url');
          var url = _this.get('router.url'); //const title = this.getWithDefault('routeName', 'unknown');


          var title = _this.get('title');

          Ember.get(_this, 'metrics').trackPage({
            url: url,
            title: title
          });
        }
      });
    }
  });

  _exports.default = _default;
});