define("spotassist/templates/auth/login", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "QAGqSuBF",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[23,[\"session\",\"isAuthenticated\"]]],null,{\"statements\":[[0,\"\\tSigned in as \"],[1,[23,[\"session\",\"user\",\"fullname\"]],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[7,\"div\"],[11,\"class\",\"container container-signup\"],[9],[0,\"\\n        \"],[7,\"div\"],[11,\"class\",\"row\"],[9],[0,\"\\n            \"],[7,\"div\"],[11,\"class\",\"col-md-6 col-md-offset-3\"],[9],[0,\"\\n                \"],[1,[27,\"login-form\",null,[[\"fullPage\",\"formType\"],[true,\"login\"]]],false],[0,\"\\n            \"],[10],[0,\"\\n        \"],[10],[0,\"\\n\\n    \"],[10],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "spotassist/templates/auth/login.hbs"
    }
  });

  _exports.default = _default;
});