define("spotassist/templates/auth/registered", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "uCZYg52u",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"container\"],[9],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"page-header \"],[9],[0,\"\\n        \"],[7,\"h3\"],[9],[0,\"Thank you for registering \"],[10],[0,\"\\n\\n        \"],[7,\"p\"],[11,\"class\",\"lead\"],[9],[0,\"\\n            You now have an access to some administrative functions\\n        \"],[10],[0,\"\\n\\n        \"],[7,\"p\"],[11,\"class\",\"lead\"],[9],[0,\"\\n            \"],[2,\"a href=\\\"#\\\" {{action \\\"gotoPreRegistered\\\"}}>Return where you started the registration.</a\"],[0,\"\\n        \"],[10],[0,\"\\n    \"],[10],[0,\"\\n\"],[10],[0,\"\\n\\n\\n\"],[1,[21,\"outlet\"],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "spotassist/templates/auth/registered.hbs"
    }
  });

  _exports.default = _default;
});