define("spotassist/models/taf-station", ["exports", "spotassist/config/environment", "ember-data/model", "ember-data/attr", "moment"], function (_exports, _environment, _model, _attr, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /* global L */
  var _default = _model.default.extend({
    name: (0, _attr.default)('string'),
    lat: (0, _attr.default)('string'),
    lon: (0, _attr.default)('string'),
    site: (0, _attr.default)('string'),
    download_time: (0, _attr.default)(),
    forecast_url: (0, _attr.default)(),
    current_url: (0, _attr.default)(),
    location: Ember.computed('lat', 'lon', function () {
      return [this.get('lat'), this.get('lon')];
    }),
    displayName: Ember.computed('site', function () {
      return this.get('site');
    }),
    icon: Ember.computed('upToDate', function () {
      //console.log('icon change for '+this.get('dz_name')+' selected:'+this.get('selected'));
      if (this.get('upToDate')) {
        return new L.icon({
          iconUrl: _environment.default.rootURL + 'assets/images/openweather_icon.png'
        });
      } else {
        return new L.icon({
          iconUrl: _environment.default.rootURL + 'assets/images/openweather_icon_outdated.png'
        });
      }
    }),
    upToDate: Ember.computed('download_time', function () {
      var validDate = (0, _moment.default)(this.get('download_time')).utc();
      var nowValidDate = (0, _moment.default)().utc();
      var diffHours = Math.abs(validDate.diff(nowValidDate, 'hours'));
      return diffHours < 3;
    })
  });

  _exports.default = _default;
});