define("spotassist/objects/gfs-station", ["exports", "spotassist/objects/metar"], function (_exports, _metar) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var GfsStation = Ember.Object.extend({
    init: function init() {
      this._super();
      /*
      var windaloftArray = [];
      var windaloft = this.get('windaloft');
      var flightlevels = [];
      windaloft.flightlevels.forEach(function(flightlevel) {
        flightlevels.push(flightlevel);
      });
      windaloft.flightlevels = flightlevels;
      windaloftArray.push(windaloft);
      this.set('windaloft', windaloftArray);
      */

      /*
      var metarArray = [];
      var metar = this.get('metar');
      metar.forEach(function() {
        metarArray.push(metar);
      });
       this.set('metar', metarArray);
      */

    },
    setGfs: function setGfs(gfs) {
      this.set('gfs', gfs);
    },
    isGfs: function isGfs() {
      return this.get('gfs');
    }
  });
  var _default = GfsStation;
  _exports.default = _default;
});