define("spotassist/controllers/usage", ["exports", "spotassist/config/environment", "spotassist/utils/global-error-handler"], function (_exports, _environment, _globalErrorHandler) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /* global L */
  var UsageSpot = Ember.Object.extend({
    location: Ember.computed('lat', 'lon', function () {
      return [this.get('lat'), this.get('lon')];
    }),
    icon: Ember.computed(function () {
      return new L.icon({
        iconUrl: _environment.default.rootURL + 'assets/images/prayer.png'
      });
    })
  });

  var _default = Ember.Controller.extend({
    ajax: Ember.inject.service(),
    satteliteMode: Ember.computed.readOnly('user-settings.satteliteMode'),
    googleStreets: L.tileLayer('//{s}.google.com/vt/lyrs=m&x={x}&y={y}&z={z}', {
      maxZoom: 20,
      subdomains: ['mt0', 'mt1', 'mt2', 'mt3']
    }),
    googleHybrid: L.tileLayer('//{s}.google.com/vt/lyrs=s,h&x={x}&y={y}&z={z}', {
      maxZoom: 20,
      subdomains: ['mt0', 'mt1', 'mt2', 'mt3']
    }),
    googleSat: L.tileLayer('//{s}.google.com/vt/lyrs=s&x={x}&y={y}&z={z}', {
      maxZoom: 20,
      subdomains: ['mt0', 'mt1', 'mt2', 'mt3']
    }),
    googleTerrain: L.tileLayer('//{s}.google.com/vt/lyrs=p&x={x}&y={y}&z={z}', {
      maxZoom: 20,
      subdomains: ['mt0', 'mt1', 'mt2', 'mt3']
    }),
    queryParams: ['lat', 'lon', 'zoom', 'standalone'],
    lat: 20,
    lon: 10,
    zoom: 3,
    standalone: false,
    init: function init() {
      this._super();
    },
    standaloneObserver: Ember.observer('standalone', function () {
      this.set("user-settings.hide-app-menu", this.get('standalone'));
    }),
    actions: {
      loadSidebar: function loadSidebar(map) {
        L.control.zoom({
          position: 'topright'
        }).addTo(map.target); //L.control.layers(null, overlayPane, {position: 'topleft'}).addTo(map.target);c

        this.set('map', map.target);
        map.target.addLayer(this.googleStreets);

        if (this.get('zoom') >= 17) {
          this.get('user-settings').set('satteliteMode', true);
        } //{{tile-layer url="http://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}.png"}}


        this.loadUsage();
      }
    },
    loadUsage: function loadUsage() {
      /*
      this.get('ajax').request('/usage/?limit=None').then(function(data) {
       });
      */
      this.set('loadingMap', true);
      this.set('loadingMapError', false);
      var that = this;
      var requestInfoUrl = _environment.default.adapter_host + "/usage/?limit=None";
      Ember.$.ajax(requestInfoUrl).then(function (usages) {
        that.set('loadingMap', false);
        that.set('loadingMapError', false);
        var usageSpots = [];
        usages.forEach(function (usage) {
          var usageSpot = UsageSpot.create(usage);
          usageSpots.push(usageSpot);
        });
        that.set('usageSpots', usageSpots);
      }, function (error) {
        var message = "Error requesting info: dropzone";
        console.error(message + error);
        (0, _globalErrorHandler.default)(error, message);
        that.set('loadingMap', false);
        that.set('loadingMapError', true); //dropzone.set('request-progress', false);
        //dropzone.set('request-error', true);
      });
      /*
      var that = this;
      this.set('loadingMap', true);
      this.store.findAll('dropzone', {reload: true, null_location: "true"}).then(function() {
        //this.store.query('dropzone', {with_null: "true"}).then(function(dropzones) {
        that.set('loadingMap', false);
         that.set('dropzonesLoaded', true);
        //that.set('registeredDropzones', dropzones);
      }, function(error) {
        var message = "Error loading store: dropzone";
        console.error(message+error);
        globalErrorHandler(error, message);
        that.set('loadingMap', false);
        that.set('loadingMapError', true);
      });
      */
    },
    satteliteModeSwitcher: Ember.observer('satteliteMode', function () {
      if (this.get('satteliteMode')) {
        this.get('map').removeLayer(this.googleStreets);
        this.get('map').addLayer(this.googleHybrid);
      } else {
        this.get('map').removeLayer(this.googleHybrid);
        this.get('map').addLayer(this.googleStreets);
      }
    })
  });

  _exports.default = _default;
});