define("spotassist/templates/application", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "oHVY5rl/",
    "block": "{\"symbols\":[],\"statements\":[[1,[21,\"head-layout\"],false],[0,\"\\n\\n\"],[1,[27,\"nav-bar\",null,[[\"session\",\"closeNavAction\"],[[23,[\"session\"]],[23,[\"closeNavAction\"]]]]],false],[0,\"\\n\\n\\n\"],[4,\"unless\",[[23,[\"user-settings\",\"hide-app-menu\"]]],null,{\"statements\":[[0,\"    \"],[1,[21,\"top-navbar\"],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[7,\"div\"],[12,\"class\",[28,[\"fill \",[27,\"if\",[[23,[\"user-settings\",\"hide-app-menu\"]],\"fill-topless\"],null]]]],[9],[0,\"\\n\\n\\t\"],[1,[21,\"outlet\"],false],[0,\"\\n\\n\"],[10],[0,\"\\n\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "spotassist/templates/application.hbs"
    }
  });

  _exports.default = _default;
});