define("spotassist/routes/dropzones/crowd", ["exports", "ember-cli-pagination/remote/route-mixin", "spotassist/mixins/trackable-route", "spotassist/mixins/seo-route"], function (_exports, _routeMixin, _trackableRoute, _seoRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_trackableRoute.default, _seoRoute.default, _routeMixin.default, {
    perPage: 25,
    actions: {
      openNavAction: function openNavAction() {
        this.get('user-settings').openNav();
      },
      closeNavAction: function closeNavAction() {
        this.get('user-settings').closeNav();
      },
      didTransition: function didTransition() {
        this._super.apply(this, arguments);

        this.trackPage();
      }
    },
    model: function model(params) {
      var that = this;
      params.undigested = 'true';
      var pagedModel = this.findPaged('crowd-user-dropzone', params);
      pagedModel.addObserver('loading', function () {
        that.set('loadingPage', pagedModel.get('loading'));
      });
      return pagedModel;
    },
    loadingObserver: Ember.observer('loadingPage', function () {
      this.controllerFor('dropzones/crowd').set('loadingPage', this.get('loadingPage'));
    }),
    beforeModel: function beforeModel() {
      //beforeModel: function(transition) {
      this.get('user-settings').closeNav();
      this.get('user-settings').set('nav-bar-map-extension', true);
    },
    afterModel: function afterModel(model) {
      var title = "Crowd Tool";
      var description = "Crowd Tool";
      var imageUrl = 'http://www.spotassist.com/wp-content/uploads/2017/02/dropzones-spotassist.png';
      this.setHeadTags(model, title, description, imageUrl);
    }
  });

  _exports.default = _default;
});