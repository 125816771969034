define("spotassist/resolver", ["exports", "ember-resolver"], function (_exports, _emberResolver) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  //import "babel-polyfill";
  var _default = _emberResolver.default;
  _exports.default = _default;
});