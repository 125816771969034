define("spotassist/routes/auth/activate", ["exports", "@ember", "spotassist/config/environment", "spotassist/utils/global-error-handler"], function (_exports, _ember, _environment, _globalErrorHandler) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    cookies: Ember.inject.service('cookies'),
    model: function model(params) {
      var csrftoken = this.get('cookies').read('csrftoken');
      Ember.$.ajax({
        type: "POST",
        headers: {
          "X-CSRFToken": csrftoken
        },
        url: _environment.default.adapter_host + '/users/activate/',
        //url: ENV.adapter_host + '/users/atoken/',
        data: params,
        dataType: 'json',
        success: function success() {
          //resolve(user);
          _ember.default.info("activation success");
        },
        error: function error(_error) {
          var message = 'Unable to activation user with ' + params + ' credentials error:' + _error;

          _ember.default.error(message);

          (0, _globalErrorHandler.default)(_error, message);

          _ember.default.error("register failure");
        }
      });
      return null;
    }
  });

  _exports.default = _default;
});