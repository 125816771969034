define("spotassist/routes/auth/login", ["exports", "ember-simple-auth/mixins/unauthenticated-route-mixin"], function (_exports, _unauthenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_unauthenticatedRouteMixin.default, {
    session: Ember.inject.service('session'),
    router: Ember.inject.service(),

    /*
    beforeModel: function(transition) {
      var currentRouteName = this.controllerFor('application').get('currentRouteName');
      if (!this.get('session').get('isAuthenticated')) {
        this.set('session.lastUnauthenticatedRoute', currentRouteName);
        if(this.get('router').router.currentHandlerInfos) {
          var currentRouteParams = this.get('router').router.currentHandlerInfos.findBy('name', currentRouteName).params;
          this.set('session.lastUnauthenticatedRouteParams', currentRouteParams);
        }
      }
       this._super(transition);
      //this.set("user-settings.hide-app-menu", true);
      //this.get('user-settings').set('nav-bar-map-extension', false);
    },
    */
    actions: {
      authenticateFacebook: function authenticateFacebook() {
        this.get('session').authenticate('authenticator:torii', 'facebook-connect');
      },
      authenticateGoogle: function authenticateGoogle() {
        this.get('session').authenticate('authenticator:torii', 'google-oauth2');
      }
    }
  });

  _exports.default = _default;
});