define("spotassist/templates/dialogs/need-login", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "fiKED3Tk",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"panel panel-default\"],[9],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"panel-heading\"],[9],[0,\"\\n        \"],[7,\"h3\"],[11,\"class\",\"panel-title\"],[9],[0,\"You need to have some permissions\"],[10],[0,\"\\n    \"],[10],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"panel-body\"],[9],[0,\"\\n        \"],[7,\"p\"],[9],[0,\"\\n            We made it available for users to report weather conditions.\\n        \"],[10],[0,\"\\n        \"],[7,\"p\"],[9],[0,\"\\n            However, the weather you enter will be visible to all users, so there is some level of responsibility.\\n        \"],[10],[0,\"\\n        \"],[7,\"p\"],[9],[0,\"\\n            We would love to know who you are.\\n        \"],[10],[0,\"\\n        \"],[7,\"p\"],[9],[0,\"\\n            Please\\n\"],[4,\"if\",[[23,[\"contextObject\",\"session\",\"isAuthenticated\"]]],null,{\"statements\":[],\"parameters\":[]},{\"statements\":[[0,\"                sign up and\\n\"]],\"parameters\":[]}],[0,\"             \"],[7,\"a\"],[11,\"href\",\"mailto:info@spotassist.com\"],[11,\"target\",\"_blank\"],[11,\"class\",\"btn btn-warning\"],[9],[0,\"Email Us\"],[10],[0,\" about who you are and what dropzone do you want to manage.\\n        \"],[10],[0,\"\\n    \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "spotassist/templates/dialogs/need-login.hbs"
    }
  });

  _exports.default = _default;
});