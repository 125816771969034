define("spotassist/utils/correct-weather", ["exports", "spotassist/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = correctWeather;

  function correctWeather(metarObject, successCallback, errorCallback) {
    var weatherUrl = _environment.default.adapter_host + "/metar-correction/" + metarObject.get('id') + '/'; //console.log("will request dz weather:"+weatherUrl);

    var metarJson = JSON.stringify(metarObject);
    Ember.$.ajax({
      type: "POST",

      /*
      headers: {
        "X-CSRFToken": Ember.$.cookie('csrftoken'),
        'Authorization':'Token '+this.get('session.data.authenticated.token')
      },
      */
      url: weatherUrl,
      data: JSON.parse(metarJson),
      //dataType: 'json',
      success: function success(response) {
        successCallback(response);
      },
      error: function error(_error) {
        var message = "Error loading url: " + weatherUrl;
        console.error(message + _error);
        errorCallback(_error, message);
      }
    });
  }
});