define("spotassist/objects/metar", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Metar = Ember.Object.extend({
    wind_speed_ktValue: Ember.computed('wind_speed_kt', 'corrected_wind_speed_kt', function () {
      var corrected_wind_speed_kt = this.get('corrected_wind_speed_kt');

      if (corrected_wind_speed_kt) {
        return corrected_wind_speed_kt;
      } else {
        return this.get('wind_speed_kt');
      }
    }),
    wind_dir_degreesValue: Ember.computed('wind_dir_degrees', 'corrected_wind_dir_degrees', function () {
      var corrected_wind_dir_degrees = this.get('corrected_wind_dir_degrees');

      if (corrected_wind_dir_degrees) {
        return corrected_wind_dir_degrees;
      } else {
        return this.get('wind_dir_degrees');
      }
    }),
    temp_cValue: Ember.computed('temp_c', 'corrected_temp_c', function () {
      var corrected_temp_c = this.get('corrected_temp_c');

      if (corrected_temp_c) {
        return corrected_temp_c;
      } else {
        return this.get('temp_c');
      }
    })
  });
  var _default = Metar;
  _exports.default = _default;
});