define("spotassist/models/anonymous-dropzone", ["exports", "ember-data", "ember-data/attr"], function (_exports, _emberData, _attr) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    user_uuid: (0, _attr.default)('string'),
    dz_id: (0, _attr.default)('string'),
    dz_name: (0, _attr.default)('string'),
    dz_address: (0, _attr.default)('string'),
    lat: (0, _attr.default)('string'),
    lon: (0, _attr.default)('string'),
    dz_phone: (0, _attr.default)('string'),
    dz_email: (0, _attr.default)('string'),
    dz_url: (0, _attr.default)('string'),
    facebook_url: (0, _attr.default)('string'),
    dz_elevation_m: (0, _attr.default)('string'),
    dz_ident: (0, _attr.default)('string'),
    active: (0, _attr.default)()
  });

  _exports.default = _default;
});