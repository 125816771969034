define("spotassist/components/nav-bar", ["exports", "spotassist/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    rootURL: _environment.default.rootURL,
    actions: {
      closeNavAction: function closeNavAction() {
        //this.sendAction('closeNavAction');
        document.getElementById("mySidenav").style.width = "0";
        document.body.style.backgroundColor = "white";
      },
      toggleSatteliteMode: function toggleSatteliteMode() {
        this.get('user-settings').set('satteliteMode', !this.get('user-settings').get('satteliteMode'));
        this.send('closeNavAction');
      },
      toggleCrowdDropzones: function toggleCrowdDropzones() {
        this.get('user-settings').set('crowdDropzonesOn', !this.get('user-settings').get('crowdDropzonesOn'));
        this.send('closeNavAction');
      },
      toggleRegisteredDropzones: function toggleRegisteredDropzones() {
        this.get('user-settings').set('registeredDropzonesOn', !this.get('user-settings').get('registeredDropzonesOn'));
        this.send('closeNavAction');
      },
      toggleRadiusWeatherStations: function toggleRadiusWeatherStations() {
        this.get('user-settings').set('radiusWeatherStationsOn', !this.get('user-settings').get('radiusWeatherStationsOn'));
        this.send('closeNavAction');
      }
    }
  });

  _exports.default = _default;
});