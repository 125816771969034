define("spotassist/models/permission", ["exports", "ember-data/model", "ember-data/attr"], function (_exports, _model, _attr) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  //import Ember from 'ember';
  //import moment from 'moment';
  var _default = _model.default.extend({
    user_email: (0, _attr.default)(),
    //object_id: attr("number"),
    object_id: (0, _attr.default)("string"),
    permission_name: (0, _attr.default)(),
    permission_valid_to: (0, _attr.default)(),
    type: (0, _attr.default)() //user_abilities: DS.hasMany('user-ability')

  });

  _exports.default = _default;
});