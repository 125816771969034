define("spotassist/utils/encode-params", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = encodeParams;

  function encodeParams(paramsMap) {
    return Object.keys(paramsMap).map(function (key) {
      return [key, paramsMap[key]].map(encodeURIComponent).join("=");
    }).join("&");
  }
});