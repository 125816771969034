define("spotassist/objects/gfs-location-object", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  //import ENV from "spotassist/config/environment";

  /* global com */
  var GfsLocation = Ember.Object.extend({
    type: null,
    site: null,
    ident: null,
    coordinates: null,
    init: function init() {
      this._super();

      this.set('__interfaces', "com.livewings.spotassist.library.gfs.IGfsLocation");
    },
    getType: function getType() {
      return this.get('type');
    },
    getCoordinates: function getCoordinates() {
      var coordinates = new java.util.ArrayList();
      coordinates.add(this.get('coordinates')[0]);
      coordinates.add(this.get('coordinates')[1]);
      return coordinates;
    },
    getSite: function getSite() {
      return this.get('site');
    },
    getIdent: function getIdent() {
      return this.get('ident');
    }
  });
  var _default = GfsLocation;
  _exports.default = _default;
});