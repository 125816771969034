define("spotassist/templates/dialogs/need-login-layout", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Lpx+8CpP",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[12,\"class\",[28,[\"ember-dialog-dialog \",[21,\"className\"],\" \",[27,\"if\",[[23,[\"substrate\"]],\"substrate\"],null]]]],[9],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"dialog-content\"],[11,\"tabindex\",\"-1\"],[9],[0,\"\\n\\n        \"],[7,\"div\"],[11,\"class\",\"dialog-header\"],[9],[0,\"\\n            \"],[7,\"button\"],[11,\"class\",\"dialog-close\"],[11,\"type\",\"button\"],[9],[3,\"action\",[[22,0,[]],\"decline\"]],[10],[0,\"\\n            \"],[7,\"div\"],[11,\"class\",\"dialog-title\"],[9],[1,[21,\"title\"],false],[10],[0,\"\\n        \"],[10],[0,\"\\n\\n\"],[4,\"if\",[[23,[\"templateName\"]]],null,{\"statements\":[[0,\"            \"],[7,\"div\"],[11,\"class\",\"dialog-body\"],[9],[15,[23,[\"templateName\"]],[]],[10],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"            \"],[1,[27,\"component\",[\"dialog-body\"],[[\"layout\",\"contextObject\",\"context\",\"class\"],[[23,[\"template\"]],[23,[\"contextObject\"]],[23,[\"context\"]],\"dialog-body\"]]],false],[0,\"\\n\"]],\"parameters\":[]}],[0,\"\\n        \"],[7,\"div\"],[11,\"class\",\"dialog-footer\"],[9],[0,\"\\n            \"],[7,\"button\"],[11,\"class\",\"btn btn__accept\"],[11,\"type\",\"button\"],[9],[0,\"Login Or Sign Up\"],[3,\"action\",[[22,0,[]],\"accept\"]],[10],[0,\"\\n            \"],[7,\"button\"],[11,\"class\",\"btn btn__decline\"],[11,\"type\",\"button\"],[9],[0,\"Cancel\"],[3,\"action\",[[22,0,[]],\"decline\"]],[10],[0,\"\\n        \"],[10],[0,\"\\n\\n    \"],[10],[0,\"\\n\"],[10]],\"hasEval\":true}",
    "meta": {
      "moduleName": "spotassist/templates/dialogs/need-login-layout.hbs"
    }
  });

  _exports.default = _default;
});