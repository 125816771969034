define("spotassist/templates/usage", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "7LHgOdca",
    "block": "{\"symbols\":[\"context\",\"usageSpot\"],\"statements\":[[4,\"if\",[[23,[\"user-settings\",\"hide-app-menu\"]]],null,{\"statements\":[[0,\"    \"],[7,\"div\"],[11,\"class\",\"slide_caption\"],[9],[0,\"\\n        \"],[7,\"div\"],[9],[0,\"\\n            \"],[7,\"p\"],[9],[0,\"Today Skydivers used Spot Assist to plan a jump in this places\"],[10],[0,\"\\n        \"],[10],[0,\"\\n    \"],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"if\",[[23,[\"loadingMap\"]]],null,{\"statements\":[[0,\"    \"],[7,\"div\"],[11,\"id\",\"loader\"],[11,\"class\",\"loader\"],[9],[0,\"\\n        \"],[7,\"h4\"],[9],[0,\"Loading...\"],[10],[0,\"\\n    \"],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"if\",[[23,[\"loadingMapError\"]]],null,{\"statements\":[[0,\"    \"],[7,\"div\"],[11,\"id\",\"loader\"],[11,\"class\",\"loader\"],[9],[0,\"\\n        \"],[7,\"h4\"],[9],[0,\"Loading failed...\"],[10],[0,\"\\n    \"],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[7,\"div\"],[11,\"id\",\"map-container\"],[9],[0,\"\\n\"],[4,\"leaflet-map\",null,[[\"lat\",\"lng\",\"scrollWheelZoom\",\"zoomControl\",\"zoom\",\"maxZoom\",\"onLoad\",\"onClick\"],[[23,[\"lat\"]],[23,[\"lon\"]],false,false,[23,[\"zoom\"]],25,[27,\"action\",[[22,0,[]],\"loadSidebar\"],null],\"mapClicked\"]],{\"statements\":[[4,\"marker-cluster-layer\",null,null,{\"statements\":[[4,\"each\",[[23,[\"usageSpots\"]]],null,{\"statements\":[[0,\"                \"],[1,[27,\"marker-layer\",null,[[\"location\",\"title\",\"alt\",\"icon\"],[[22,2,[\"location\"]],[22,2,[\"id\"]],[22,2,[\"id\"]],[22,2,[\"icon\"]]]]],false],[0,\"\\n\"]],\"parameters\":[2]},null]],\"parameters\":[]},null]],\"parameters\":[1]},null],[10],[0,\"\\n\\n\\n\"],[1,[21,\"outlet\"],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "spotassist/templates/usage.hbs"
    }
  });

  _exports.default = _default;
});