define("spotassist/components/digester-button", ["exports", "moment", "spotassist/utils/global-error-handler"], function (_exports, _moment, _globalErrorHandler) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    actions: {
      markDigestedAction: function markDigestedAction() {
        var dropzone = this.get('dropzone');
        var that = this;
        dropzone.set('digest-progress', true);
        dropzone.set('digest-error', false);
        dropzone.set('digested', (0, _moment.default)().utc().format());
        dropzone.save().then(function () {
          dropzone.set('digest-progress', false);
          dropzone.set('digest-error', false);

          if (that.get('model')) {//that.get('model').set('promise', that.get('model').fetchContent());
          }
        }, function (error) {
          var message = "Error saving store: dropzone";
          console.error(message + error);
          (0, _globalErrorHandler.default)(error, message);
          dropzone.set('digest-progress', false);
          dropzone.set('digest-error', true);
        });
      }
    },
    progress: Ember.computed('dropzone.digest-progress', function () {
      return this.get('dropzone.digest-progress');
    }),
    error: Ember.computed('dropzone.digest-error', function () {
      return this.get('dropzone.digest-error');
    }),
    success: Ember.computed('dropzone.{digest-progress,digest-error}', function () {
      if (this.get('dropzone.digest-progress') == null) {
        return false;
      } else {
        return !this.get('dropzone.digest-progress') && !this.get('dropzone.digest-error');
      }
    })
  });

  _exports.default = _default;
});