define("spotassist/utils/generate-public-url", ["exports", "spotassist/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = generatePublicUrl;

  function generatePublicUrl(dropzoneId, alias) {
    return _environment.default.SHARE_HOST + "/#!/dropzones/" + dropzoneId + "/bigpicture?public=" + alias;
  }
});