define("spotassist/templates/raob-stations/loading", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "u5hG0H+r",
    "block": "{\"symbols\":[],\"statements\":[[0,\"Raobs are loading\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "spotassist/templates/raob-stations/loading.hbs"
    }
  });

  _exports.default = _default;
});