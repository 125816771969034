define("spotassist/instance-initializers/ember-dialog", ["exports", "spotassist/config/environment", "ember-dialog/configuration"], function (_exports, _environment, _configuration) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.initialize = initialize;
  _exports.default = void 0;

  function initialize(application) {
    _configuration.default.load(_environment.default);

    application.inject('controller', 'dialog', 'service:dialog');
  }

  var _default = {
    name: 'ember-dialog',
    initialize: initialize
  };
  _exports.default = _default;
});