define("spotassist/templates/dialogs/i-want-it", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "agwmKiw/",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"panel panel-default\"],[9],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"panel-heading\"],[9],[0,\"\\n        \"],[7,\"h3\"],[11,\"class\",\"panel-title\"],[9],[0,\"Spread the word about \"],[1,[23,[\"contextObject\",\"currentDropzone\",\"dz_name\"]],false],[0,\" Big Picture\"],[10],[0,\"\\n    \"],[10],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"panel-body\"],[9],[0,\"\\n        We are contacting dropzone owners and skydivers all over the world with this great tool.\\n        Please share to show the interest, if you want to see it on your dropzone.\\n\\n        \"],[7,\"hr\"],[11,\"class\",\"half-rule\"],[9],[10],[0,\"\\n\\n        \"],[1,[27,\"share-panel\",null,[[\"url\",\"buttons\",\"labels\"],[[23,[\"contextObject\",\"facebookCurrentDropzoneUrl\"]],\"fb,twitter\",\"Share on Facebook,Tweet it\"]]],false],[0,\"\\n\\n\\n\"],[4,\"if\",[[23,[\"contextObject\",\"session\",\"isAuthenticated\"]]],null,{\"statements\":[],\"parameters\":[]},null],[0,\"\\n\\n    \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "spotassist/templates/dialogs/i-want-it.hbs"
    }
  });

  _exports.default = _default;
});