define("spotassist/models/bigpicture-purchase", ["exports", "ember-data/model", "ember-data/attr"], function (_exports, _model, _attr) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  //import Ember from 'ember';
  var _default = _model.default.extend({
    product: (0, _attr.default)(),
    amount: (0, _attr.default)(),
    email: (0, _attr.default)(),
    stripe_token: (0, _attr.default)(),
    dz_id: (0, _attr.default)()
  });

  _exports.default = _default;
});